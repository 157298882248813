// image slider
(function() {
    // main object
    var pbSlider = function(jEl, args) {
        // pointer to self
        var self = this;

        // timer
        var timer = -1;

        // options
        var options = {
            speed: 1700,
            delay: 4000,
            autoWidth: false,

            pagingContainer: $('.slider-pages'),
            pagingItem: $('.slider-pages-item'),
            pagingItemHTML: '<span class="slider-pages-item"></span>',

            center: false,

            onInit: function() {},

            onSlideChange: function() {}
        };

        // map the arguments to options
        for(var a in args) {
            if(args.hasOwnProperty(a))
                options[a] = args[a];
        };

        var swch = false;
        var _addSwch = function ($el) {
            if (swch) {
                $el.addClass('_enl');
            }
            else {
                $el.removeClass('_enl');
            }
            window.setTimeout(function () {
                if (swch) {
                    $el.removeClass('_enl');
                }
                else {
                    $el.addClass('_enl');
                }
            }, 50);
        }

        // get elements inside slider
        var container = jEl;
        var width = jEl.outerWidth();
        var images = $('img', jEl);
        var elements = jEl.children();
        var elementWidth = elements.first().outerWidth();

        var amount = elements.length;
        var count = 0;

        // container
        //container.css({position: 'relative'});
        // set image width
        if(options.autoWidth)
            images.css({width: width});
        // position slider elements
        // elements.css({position: 'absolute', top: 0, left: 0, 'z-index': 0});
        // center elements
        if(options.center === true)
            elements.css({left: '50%', 'margin-left': '-'+(elementWidth/2)+'px'});
        elements.first().addClass('_active');

        // add paging controls
        options.pagingContainer.empty();
        for(var i=0; i<amount; i++) {
            $(options.pagingItemHTML).appendTo(options.pagingContainer).css('background-image', elements.eq(i).css('background-image'));
        }

        // 'select' the first slide
        var $elFirstPage = $(options.pagingItem.selector).first();
        $elFirstPage.addClass('_active');

        var $elFirst = elements.eq(0);
        _addSwch($elFirst);

        // assign click events for paging
        $(options.pagingItem.selector).click(function() {
            roll($(this).index());
        });

        var jRe = jEl.closest('.top-slider-wrapper');

        if (jRe.length) {
            $('.tsw-left', jRe).click(function () {
                var cc = count || 0;       
                var next = ((cc-1) == -1) ? amount-1 : cc-1;
                roll(next);
            });


            $('.tsw-right', jRe).click(function () {
                var cc = count || 0;       
                var next = ((cc+1) == amount) ? 0 : cc+1;
                console.log(next);
                roll(next);
            });
        }


        options.onInit.call(this);

        var $prev = $('.project-paging-prev');
        var $next = $('.project-paging-next');

        $prev.click(function () {
            var ind = (count-1 >= 0) ? count-1 : amount-1;
            roll(ind);
        });

        $next.click(function () {
            var ind = (count+1 < amount) ? count+1 : 0;
            roll(ind);
        });

        // Roll!
        var roll = function(ind, cssClass) {
            // fade in/out slider items
            if(count!=ind) { 
                swch = !swch;

                var prev = count;          
                var next = ((count+1) == amount) ? 0 : count+1;
                if(typeof(ind) != 'undefined') {
                    next = ind;
                }
                
                var $elCur = $(elements[count]);
                var $elNxt = $(elements[next]);
                
                _addSwch($elNxt);

                $elCur.removeClass('_active');
                $elNxt.addClass('_active');
                if (cssClass) {
                    $elCur.addClass(cssClass);
                    $elNxt.removeClass(cssClass);
                    window.setTimeout(function () {
                        $elCur.removeClass(cssClass);
                    }, 750);
                }

                count = ((count+1) == amount) ? 0 : count + 1;
                
                if(typeof(ind) != 'undefined') {
                    count = ind;
                }

                // add active css class to the current slider page
                $(options.pagingItem.selector).removeClass('_active');
                $($(options.pagingItem.selector)[next]).addClass('_active');

                if (options.onSlideChange) {
                	options.onSlideChange.call(this, count);
                }

                // clear all previous timer
                window.clearTimeout(timer);
                // add the current timer
                timer = window.setTimeout(roll, options.delay);
            }
        };
        if (elements.length > 1) {
            timer = window.setTimeout(roll, options.delay); // woo!
        }

        jEl.data('slider', this);

        this.destroy = function () {
        	window.clearTimeout(timer);
        }
        this.prevSlide = function () {
            var ind = (count-1 >= 0) ? count-1 : amount-1;
            roll(ind, '_touch _rev');
        }
        this.nextSlide = function () {
            var ind = (count+1 < amount) ? count+1 : 0;
            roll(ind, '_touch');
        }
    };

    // jquery plugin
    $.fn.pbSlider = function(args) {
        $(this).each(function () {
            if (!window.sliders) {
                window.sliders = [];
            }
            if (args === 'destroy') {
            	$(this).data('slider').destroy();
            }
            else if (args === 'prevSlide') {
                $(this).data('slider').prevSlide();
            }
            else if (args === 'nextSlide') {
                $(this).data('slider').nextSlide();
            }
            else {
                var sl = new pbSlider($(this), args);
	            window.sliders.push(sl);
                $(this).data('slider', sl);
            }
        });
        return this;  
    };
})();
