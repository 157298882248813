(function (module, $) {
	'use strict';

	// 'singleton' kinda..
	if (module.ssRouter) {
		console.log(':: ssRouter is already defined..');
		return null;
	}

	// elements
	var $module = $(module);
	var $body = $('body');

	function ssRouter ($el, args) {
		// pointer to self
		var self = this;
		// current page
		var currentPage;


		// options
		var settings = {
			templatePath: 'templates/',
			defaultRoute: 'home', // loads this route if none is specified
			navItemSelector: '.nav-item',
			loadDelay: 150,
			beforeLoadCallback: null,
			afterLoadCallback: null,
			routes: {
				home: {
					template: 'home.html'
				}
			}
		};

		// map the arguments to settings
		for(var a in args) {
			if(args.hasOwnProperty(a))
				settings[a] = args[a];
		};

			
		// bind router events
		var self = this;
		$module.on('load hashchange', function () {
			self.loadPage(document.location.hash);
		});
		
		// :: LOAD TEMPLATE
		self.loadPage = function (id, callback) {
			
			// get page id
			var pageId = id || settings.defaultRoute;
			pageId = pageId.replace('#!', ''); // replace in case id is raw hash
			pageId = pageId.replace(/\:\S+$/i, ''); // replace arg

			// get page argument
			var pageArg = (id.match(/\:(\S+)$/i)) ? id.match(/\:(\S+)$/i)[1] : null;

			var page = settings.routes[pageId];
			var pageRoute = {
				id: pageId,
				arg: pageArg
			};
			var loadClass = page.onLoadClass || '_load';
			var unloadClass = loadClass;

			// global after load callback
			if (settings.beforeLoadCallback) {
				settings.beforeLoadCallback(pageId, pageArg);
			}

			if (currentPage) {
				unloadClass = currentPage.onUnloadClass || currentPage.onLoadClass || '_load';

				// currentPage onUnload callback
				if (currentPage.onUnload) {
					currentPage.onUnload(currentPage, $el);
				}
			}

			// add unload css class
			$el.addClass(unloadClass);
			var containerTransitionDelay = parseFloat($el.css('transition-duration')) * 1000 || 10;
			containerTransitionDelay += settings.loadDelay;
			module.setTimeout(function () {

				// scroll to top
				$module.scrollTop(0)
				// reset transition class
				$el.removeClass(unloadClass);
				$el.css({ 'transition': 'none' });
				$el.addClass(loadClass);
				$el.css('transition'); // force reflow
				$el.removeAttr('style');

				// load template file
				$.get(settings.templatePath + page.template, function (data) {
					var $_page = $(data);

					// fire current page afterUnload callback
					if (currentPage && currentPage.onAfterUnload) {
						currentPage.onAfterUnload(currentPage, $el);
					}
					
					// set current page to the loaded one
					currentPage = page;

					// set container html
					$el.empty().append($_page);
					$el.removeClass(loadClass);

					// set nav item css
					var $navItemActive = $(settings.navItemSelector + '[href="#!'+ pageId +'"]');
					$(settings.navItemSelector + '._active').removeClass('_active');
					$navItemActive.addClass('_active');

					$body.attr('class', 'page-id-'+pageId.replace(/\//g, '_'));
					
					// page onLoad callback
					if (page.onLoad) {
						page.onLoad(pageRoute, $_page);
					}
					
					// specified callback
					if (callback) {
						callback(pageId, pageArg);
					}
					
					// global after load callback
					if (settings.afterLoadCallback) {
						settings.afterLoadCallback(pageId, pageArg);
					}

				}).fail(function (err) {
					// error loading template file
					console.log(':: Router can\'t load the template');
					console.log(err);
				});

			}, containerTransitionDelay);

		}
	}

	// :: EXPORT
	module.ssRouter = ssRouter;

})(this, this.jQuery);