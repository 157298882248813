(function (module) {
	'use strict';

	module.projects = {
		cluden: {
			name: "cluden",
			images: [
				"7.jpg",
				"5.jpg",
				"6.jpg",
				"4.jpg",
				"1.jpg",
				"2.jpg",
				"3.jpg",
			],
			thumbnail: "4_thumb.jpg"
		},
		bryson: {
			name: "bryson",
			images: [
				"8.jpg",
				"16.jpg",
				"1.jpg",
				"4.jpg",
				"5.jpg",
				"7.jpg",
				"15.jpg",
				"2.jpg",
				"3.jpg",
				"9.jpg",
				"10.jpg",
				"11.jpg",
				"12.jpg",
				"13.jpg",
				"14.jpg",
				"17.jpg",
			],
			thumbnail: "1_thumb.jpg"
		},
		carpenter: {
			name: "carpenter",
			images: [
				"10.jpg",
				"6.jpg",
				"15.jpg",
				"16.jpg",
				"19.jpg",
				"20.jpg",
				"24.jpg",
				"25.jpg",
				"4.jpg",
				"5.jpg",
				"8.jpg",
				"2.jpg",
				"1.jpg",
				"3.jpg",
				"9.jpg",
				"7.jpg",
				"11.jpg",
				"12.jpg",
				"13.jpg",
				"14.jpg",
				"17.jpg",
				"18.jpg",
				"22.jpg",
				"23.jpg",
			],
			thumbnail: "8_thumb.jpg"
		},
		chatsworth: {
			name: "chatsworth",
			images: [
				"7.jpg",
				"8.jpg",
				"14.jpg",
				"10.jpg",
				"12.jpg",
				"17.jpg",
				"23.jpg",
				"24.jpg",
				"25.jpg",
				"1.jpg",
				"5.jpg",
				"6.jpg",
				"4.jpg",
				"3.jpg",
				"16.jpg",
				"9.jpg",
				"11.jpg",
				"13.jpg",
				"18.jpg",
				"19.jpg",
				"15.jpg",
				"20.jpg",
				"21.jpg",
				"26.jpg",

			],
			thumbnail: "1_thumb.jpg"
		},
		black: {
			name: "black",
			images: [
				"1.jpg",
				"2.jpg",
				"9.jpg",
				"10.jpg",
				"3.jpg",
				"4.jpg",
				"5.jpg",
				"6.jpg",
				"7.jpg",
				"8.jpg",
			],
			thumbnail: "5_thumb.jpg"
		},
		bay: {
			name: "bay",
			images: [
				"11.jpg",
				"1.jpg",
				"17.jpg",
				"13.jpg",
				"19.jpg",
				"7.jpg",
				"9.jpg",
				"10.jpg",
				"2.jpg",
				"3.jpg",
				"5.jpg",
				"6.jpg",
				"4.jpg",
				"14.jpg",
				"15.jpg",
				"16.jpg",
				"18.jpg",
				"20.jpg",
			],
			thumbnail: "2_thumb.jpg"
		},
		meek: {
			name: "meek",
			images: [
				"12.jpg",
				"33.jpg",
				"13.jpg",
				"26.jpg",
				"1.jpg",
				"31.jpg",
				"16.jpg",
				"32.jpg",
				"9.jpg",
				"5.jpg",
				"8.jpg",
				"10.jpg",
				"23.jpg",
				"17.jpg",
				"34.jpg",
				"24.jpg",
				"2.jpg",
				"6.jpg",
				"7.jpg",
				"11.jpg",
				"3.jpg",
				"4.jpg",
				"18.jpg",
				"19.jpg",
				"20.jpg",
				"21.jpg",
				"22.jpg",
				"27.jpg",
				"28.jpg",
				"29.jpg",
				"30.jpg",
			],
			thumbnail: "10_thumb.jpg"
		}
	};

})(this);
