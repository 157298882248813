(function (window, $, ssRouter) {
	'use strict';
	// :: FASTCLICK
	FastClick.attach(document.body);

	// elements
	var $window = $(window);
	var $body = $('body');
	var $mainContainer = $('.s-content'); // main content section
	var $header = $('.s-header');
	var $footer = $('.s-footer');

	// 60fps scroll
	var scroll60fpsTimer = -1;
	$window.scroll(function (evt) {
	  window.clearTimeout(scroll60fpsTimer);
	  $body.addClass('__disable-hover');
	  
	  scroll60fpsTimer = window.setTimeout(function(){
	    $body.removeClass('__disable-hover');
	  }, 200);
	});

	var routeData = {};

	// set up page routes
	var routes = {
		'home': {
			template: 'home.php',
			onLoadClass: '_load-fade',
			onLoad: function (pageRoute, $el) {
				$('.slider-slides', $el).pbSlider();
			},
			onUnload: function (pageRoute, $el) {
				$('.slider-slides', $el).pbSlider('destroy');
			}
		},
		'about': {
			template: 'about.php',
			onLoadClass: '_load-fade',
			onUnloadClass: '_load-shift-bottom',
		},
		'contact': {
			template: 'contact.html',
			onLoadClass: '_load'
		},
		'gallery': {
			template: 'gallery.html',
			onLoadClass: '_load-quick',
			onUnloadClass: '_load',
			onLoad: function (pageRoute, $el) {
				var source   = $("#thumb-tpl").html();
				var template = Handlebars.compile(source);
				
				for (var p in window.projects) {
					if (window.projects.hasOwnProperty(p)) {
						var proj = window.projects[p];

						var html = template(proj);
						$('.grid-gallery', $el).prepend(html);
					}
				}
				
				var $thumbs = $('.gallery-thumb', $el);
				window.setTimeout(function () {
					for (var i=0, l=$thumbs.length; i<l; i++) {
						(function (ind) {
							window.setTimeout(function () {
								$thumbs.eq(ind).removeClass('_load');
							}, ind * 150);
						})(i);
					}
				}, 100);
			}
		},
		'gallery/project': {
			template: 'project.html',
			onLoadClass: '_load-fade',
			onLoad: function (pageRoute, $el) {
				if (pageRoute.arg) {

					$('.project-name').text(pageRoute.arg);

					var source   = $("#slide-tpl").html();
					var template = Handlebars.compile(source);
					
					for (var p in window.projects) {
						if (window.projects.hasOwnProperty(p)) {
							var proj = window.projects[p];
							if (proj.name === pageRoute.arg) {
								var html = template(proj);
								$('.slider-slides', $el).append(html);
								break;
							}
						}
					}

					
				}

				$('.slider-slides', $el).pbSlider({
					delay: 4800,
					onInit: function () {
						$('.project-paging-current').text('1');
						$('.project-paging-total').text($('.slider-item').length);
					},
					onSlideChange: function (ind) {
						$('.project-paging-current').text(ind+1);
					}
				});

				$('.slider-slides img').each(function () {
					var $this = $(this);
					var $par = $this.closest('.slider-item');
					$this.load(function (evt) {
						if (this.width < this.height) {
							$par.addClass('_contain');
						}
					});
				});

				$('.project-controll-full', $el).click(function (evt) {
					var $ps = $('.project-slider');
					var $ss = $('.slider-slides');

					if ($ps.hasClass('_zoom')) {
						$ps.removeClass('_zoom');
						$ps.removeAttr('style');
						if (routeData.windowResizeCallback) {	
							routeData.windowResizeCallback();
						}
					}
					else {
						var x = $ps.offset().left;
						var y = $ps.offset().top;
						var w = $ps.outerWidth() - x;
						var h = $ps.outerHeight() - y;
						$ps.css({
							position: 'fixed',
							left: x,
							top: y,
							width: w,
							height: h
						});
						$ps.css('transition');
						$ps.addClass('_zoom');
						$ps.css('transition');
						var ccOff = window.innerHeight - 100;
						$('.slider-slides').css({ height: ccOff });
					}
				});
			},
			onUnload: function (pageRoute, $el) {
				$('.slider-slides', $el).pbSlider('destroy');
			}
				
		}
	};

	// touch
	var startTouchX = 0;
	var touchSensitivity = 77;
	var touchSlideSpeed = 500;
	$(document).on('touchstart', '.project-slider > .slider-slides', function (evt) {
		//console.log(evt);
		evt.preventDefault();
		evt.stopPropagation();
		startTouchX = evt.originalEvent.touches[0].screenX;
	});
	$(document).on('touchend', '.project-slider > .slider-slides', function (evt) {
		//console.log(evt);
		evt.preventDefault();
		var dist = evt.originalEvent.changedTouches[0].screenX - startTouchX;
		console.log(dist);

		if (Math.abs(dist) > touchSensitivity) {
			var $this = $(this); 
			if ($this.length > 0) {
				if (dist > 0) {
					$this.pbSlider('prevSlide');
				}
				else {
					$this.pbSlider('nextSlide');
				}
			}
		}
	});

	// resize container bottom
	window.setTimeout(function () {
		$mainContainer.css({
			paddingBottom: $footer.outerHeight()
		});
	}, 1500);
	var stonningtonRouter = new ssRouter($mainContainer, {
		beforeLoadCallback: function (pageId, pageArge) {
			if ($('.slider').length) {
				if (routeData.windowResizeCallback) {	
					$window.unbind('resize', routeData.windowResizeCallback);
					routeData.windowResizeCallback = null;
				}
			}

				$('.menu-item>a').removeClass('_active');
				$('.menu-item>a[href$="'+ pageId +'"]').addClass('_active');
		},
		afterLoadCallback: function (pageId, pageArg) {
			// update scroller
			window.setTimeout(updateScroller, 200);

			// resize the slider
			var $slider = $('.slider');
			var $projectSlider = ($slider.length) ? $slider : $('.project-slider');
			if ($projectSlider.length) {
				var $sliderSlides = $('.slider-slides');
				routeData.windowResizeTimer = -1;
				routeData.windowResizeDelay = 250;
				routeData.windowResizePadding = 10;
				routeData.windowResizeCallback = function () {
					window.clearTimeout(routeData.windowResizeTimer);
					routeData.windowResizeTimer = window.setTimeout(function () {
						var footerHeight = $footer.outerHeight();
						var llHeight = window.innerHeight;
						var ssTop = $sliderSlides.offset().top;
						var ffHeight = ($projectSlider.hasClass('_zoom')) ? 50 : footerHeight;
						var wwPad = routeData.windowResizePadding;
						$sliderSlides.css({
							height: llHeight - ffHeight - wwPad - ssTop
						});
						$mainContainer.css({
							paddingBottom: footerHeight
						});
					}, routeData.windowResizeDelay);
				}
				routeData.windowResizeCallback();
				$window.on('resize', function () {
					if (routeData.windowResizeCallback) {
						routeData.windowResizeCallback();
					}
				});
			}


		},
		routes: routes
	});

	$('.nav-item').click(function (evt) {
		evt.preventDefault();

		var $this = $(this);

		document.location.hash = $this.attr('href');

		$('.nav-item._active').not(this).removeClass('_active');
		$this.addClass('_active');
	});

	$('.menu-item > a').click(function (evt) {
		evt.preventDefault();

		var $this = $(this);

		// collapse menu
		$('.menu-toggler, .menu-container').removeClass('_active');

		window.setTimeout(function () {
			document.location.hash = $this.attr('href');
		}, 200);
	});

	// :: BIO ITEMS
	$(document).on('click', '.bio-item-image', function (evt) {
		evt.preventDefault();

		$('.bio-item._active').removeClass('_active');
		$(this).closest('.bio-item').addClass('_active');
	});
	// :: FORM SUBMIT
	$(document).on('click', '.button-form-submit', function (evt) {
		evt.preventDefault();
		// check all inputs are filled
		if (
				$('input[name="name"]').val().length > 1 &&
				$('input[name="email"]').val().length > 1 &&
				$('textarea[name="message"]').val().length > 1 &&
				$('input[name="email"]').val().match(/[^@]+@[^@]+\.[^@]+/gi)
			)
		{
			$(this).closest('.content-panel').addClass('_complete');
			var txtName = $('.form input[name="name"]').val();
			$('.form-thanks-name').text(txtName);
		}
		else {
			$('.form').addClass('_validate');
		}

	});

	// :: PROJECT INFO
	$(document).on('click', '.project-control-info', function (evt) {
		var $this = $(this);
		// var $span = $('span', $this);
		$('.project-content').add(this).toggleClass('_active');
	});

	// :: NAV
	var $navToggler = $('.menu-toggler');
	var $navMenuContainer = $('.menu-container');

	$navToggler.click(function (evt) {
		evt.preventDefault();

		$(this).toggleClass('_active');
		$navMenuContainer.toggleClass('_active');
	});

	// :: UPDATE PAGE SCROLL ARROWS
	var $pageScroller = $('.page-scroller');
	var $pageScrollerUp = $('.page-scroller-arrow._arrow-up');
	var $pageScrollerDown = $('.page-scroller-arrow._arrow-down');
	var scrollValue = 150;

	$pageScrollerUp.click(function (evt) {
		scrollPage(-scrollValue);
	});

	$pageScrollerDown .click(function (evt) {
		scrollPage(scrollValue);
	});

	var scrollerDistance = 350;
	var scrollPage = function (distance) {
		var dist = $(window).scrollTop() + distance;
		$('html, body').animate({ scrollTop: dist }, scrollerDistance);
	};

	var updateScroller = function () {
		var winScrollTop = $(window).scrollTop();
		var winHeight = $(window).innerHeight();
		var docHeight = $(document).innerHeight();

		if (winHeight >= docHeight) {
			// $pageScroller.remove();
			// $pageScroller.removeClass('_active');
			return false;
		}
		else {
			$pageScroller.addClass('_active');
			if (window.innerHeight + winScrollTop < docHeight - scrollValue/2) {
				$pageScrollerDown.addClass('_active');
			}
			else {
				$pageScrollerDown.removeClass('_active');
			}
			if ( winScrollTop > scrollValue/2) {
				$pageScrollerUp.addClass('_active');
			}
			else {
				$pageScrollerUp.removeClass('_active');
			}
		}
	};

	// set up scroller update
	updateScroller();
	var updateScrollerTimeout = -1;
	var updateScrollerDelay = 500;
	$(window).on('scroll resize', function (evt) {
		window.clearTimeout(updateScrollerTimeout);
		window.setTimeout(updateScroller, updateScrollerDelay);
	});

	// :: HANDLEBARS
	Handlebars.registerHelper('thumbnail', function(name, options) {
		var thumb = window.projects[name].thumbnail || 'none';
		return 'images/img/gallery/'+ name +'/'+ thumb;
	});

	
})(this, this.jQuery, this.ssRouter);
